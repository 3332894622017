.app-over-item {
	display: flex;
	padding: 40px 20px;
	min-width: 320px;
	@include overflow(auto, hidden);
	@include absolute-fill(1000);
	position: fixed;
	.app-over-bg {
		background: rgba(#000, .9);
		pointer-events: none;
		@include absolute-fill(1000);
		position: fixed;
	}
	.app-over-item__inner {
		margin: auto;
		padding: 30px 40px;
		z-index: 1010;
		position: relative;
		background: $backgroundSecondary;
		border-radius: 3px;
	}
	&.enter {
		opacity: 0;
		.app-over-item__inner {
			opacity: 0;
			transform: translateY(5px);
		}
	}
	&.enter-active {
		opacity: 1;
		transition: opacity .2s ease, transform .2s ease;
		.app-over-item__inner {
			opacity: 1;
			transform: translateY(0);
			transition: opacity .2s ease, transform .2s ease;
		}
	}
	&.exit {
		opacity: 1;
		.app-over-item__inner {
			opacity: 1;
			transform: translateY(0);
		}
	}
	&.exit-active {
		opacity: 0;
		transition: opacity .2s ease, transform .2s ease;
		.app-over-item__inner {
			opacity: 0;
			transform: translateY(5px);
			transition: opacity .2s ease, transform .2s ease;
		}
	}
}

.over-item-title {
	@include flex(center, space-between);
	h4 {
		@include text(#000, 26px, 600);
	}
	button {
		cursor: pointer;
		padding: 9px;
		font-size: 0;
		border-radius: 3px;
		svg {
			fill: #000;
			@include size(14px);
		}
	}
}
