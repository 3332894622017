.app-square-icon {
	min-width: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: border-color .2s ease, background-color .2s ease;
	background: $backgroundTertiary;
	box-shadow: 0 4px 6px rgba(darken($backgroundSecondary, 8), .2);
	border-radius: 50%;
	svg {
		fill: $primary;
		transition: fill .2s ease;
	}
	&.clickable {
		cursor: pointer;
		&:not(:disabled):hover {
			background: lighten($backgroundTertiary, 8);
			border-color: rgba(#fff, .15);
			svg {
				fill: $primary;
			}
		}
	}
}

.app--nav-user .app-square-icon svg {
	margin-right: -3px;
}
