.app-button {
	padding: 15px;
	background: $backgroundTertiary;
	text-align: center;
	transition: background-color .2s ease;
	// box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .4);
	border-radius: 5px;
	span {
		font: 700 14px $font, Sans-serif;
		color: $textWhite;
		user-select: none;
		white-space: nowrap;
		text-transform: uppercase;
	}
	.loader {
		width: 16px;
		height: 18px;
		display: inline-block;
		vertical-align: top;
		i {
			border-width: 2px;
			border-color: #000;
			border-bottom-color: transparent;
		}
	}
	&.app-button--clickable:not(:disabled):hover {
		background: lighten($backgroundTertiary, 8);
	}
}

.app-button--clickable {
	cursor: pointer;
}

.app-button--primary {
	span {
		color: #000;
	}
	background: $primary;
	&.app-button--clickable:not(:disabled):hover {
		background: lighten($primary, 8);
	}
}

.app-button--secondary {
	background: $secondary;
	&.app-button--clickable:not(:disabled):hover {
		background: lighten($secondary, 8);
	}
}

.app-button--danger {
	background: $danger2;
	&.app-button--clickable:not(:disabled):hover {
		background: lighten($danger2, 8);
	}
}

.app-button--disabled-deep {
	opacity: .4;
}

.app-button--hollow {
	box-shadow: none;
	background: transparent;
	&.app-button--clickable:not(:disabled):hover {
		background: lighten($backgroundTertiary, 1);
	}
}

a.app-button {
	display: block;
	&:hover {
		background: lighten($backgroundTertiary, 8);
	}
}

.manage-content {
	a.app-button {
		display: inline-block;
	}
}

a.app-button--primary:hover {
	background: lighten($primary, 8);
}

a.app-button--secondary:hover {
	background: lighten($secondary, 8);
}

a.app-button--hollow {
	box-shadow: none;
	&:hover {
		background: transparent;
	}
}

form .app-button {
	cursor: pointer;
	&:not(:disabled):hover {
		background: lighten($backgroundTertiary, 8);
	}
	&.app-button--primary {
		&:not(:disabled):hover {
			background: lighten($primary, 8);
		}
	}
	&.app-button--secondary {
		background: $secondary;
		&:not(:disabled):hover {
			background: lighten($secondary, 8);
		}
	}
}
