.app--athlete-header {
	position: relative;
	.__bg,
	.__bg-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.__bg {
		z-index: 20;
		background: no-repeat top center/cover;
		min-height: 240px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		border-radius: 0 0 40px 40px;
	}
	.__bg-overlay {
		z-index: 30;
		backdrop-filter: blur(24px);
		border-radius: 0 0 30px 30px;
		$color: darken($backgroundSecondary, 6);
		@include gradient(
			to bottom,
			rgba($color, 0.15) 0%,
			rgba($color, 0.16) 8.1%,
			rgba($color, 0.189) 15.5%,
			rgba($color, 0.233) 22.5%,
			rgba($color, 0.29) 29%,
			rgba($color, 0.357) 35.3%,
			rgba($color, 0.432) 41.2%,
			rgba($color, 0.51) 47.1%,
			rgba($color, 0.59) 52.9%,
			rgba($color, 0.668) 58.8%,
			rgba($color, 0.743) 64.7%,
			rgba($color, 0.81) 71%,
			rgba($color, 0.867) 77.5%,
			rgba($color, 0.911) 84.5%,
			rgba($color, 0.94) 91.9%,
			rgba($color, 0.95) 100%
			// rgba($color, 0.43) 0%,
			// rgba($color, 0.437) 8.1%,
			// rgba($color, 0.458) 15.5%,
			// rgba($color, 0.489) 22.5%,
			// rgba($color, 0.53) 29%,
			// rgba($color, 0.578) 35.3%,
			// rgba($color, 0.631) 41.2%,
			// rgba($color, 0.687) 47.1%,
			// rgba($color, 0.743) 52.9%,
			// rgba($color, 0.799) 58.8%,
			// rgba($color, 0.852) 64.7%,
			// rgba($color, 0.9) 71%,
			// rgba($color, 0.941) 77.5%,
			// rgba($color, 0.972) 84.5%,
			// rgba($color, 0.993) 91.9%,
			// rgba($color, 100)
			// rgba(lighten($color, 0%), 0.43) 0%,
			// rgba(lighten($color, 1.24%), 0.437) 8.1%,
			// rgba(lighten($color, 2.42%), 0.458) 15.5%,
			// rgba(lighten($color, 3.55%), 0.489) 22.5%,
			// rgba(lighten($color, 4.61%), 0.53) 29%,
			// rgba(lighten($color, 5.6%), 0.578) 35.3%,
			// rgba(lighten($color, 6.53%), 0.631) 41.2%,
			// rgba(lighten($color, 7.38%), 0.687) 47.1%,
			// rgba(lighten($color, 8.16%), 0.743) 52.9%,
			// rgba(lighten($color, 8.85%), 0.799) 58.8%,
			// rgba(lighten($color, 9.47%), 0.852) 64.7%,
			// rgba(lighten($color, 9.99%), 0.9) 71%,
			// rgba(lighten($color, 10.41%), 0.941) 77.5%,
			// rgba(lighten($color, 10.73%), 0.972) 84.5%,
			// rgba(lighten($color, 10.93%), 0.993) 91.9%,
			// rgba(lighten($color, 11%), 100)
			// hsla(0, 0%, 0%, 0.43) 0%,
			// hsla(0, 0%, 1.24%, 0.437) 8.1%,
			// hsla(0, 0%, 2.42%, 0.458) 15.5%,
			// hsla(0, 0%, 3.55%, 0.489) 22.5%,
			// hsla(0, 0%, 4.61%, 0.53) 29%,
			// hsla(0, 0%, 5.6%, 0.578) 35.3%,
			// hsla(0, 0%, 6.53%, 0.631) 41.2%,
			// hsla(0, 0%, 7.38%, 0.687) 47.1%,
			// hsla(0, 0%, 8.16%, 0.743) 52.9%,
			// hsla(0, 0%, 8.85%, 0.799) 58.8%,
			// hsla(0, 0%, 9.47%, 0.852) 64.7%,
			// hsla(0, 0%, 9.99%, 0.9) 71%,
			// hsla(0, 0%, 10.41%, 0.941) 77.5%,
			// hsla(0, 0%, 10.73%, 0.972) 84.5%,
			// hsla(0, 0%, 10.93%, 0.993) 91.9%,
			// hsl(0, 0%, 11%) 100%
		);
	}
	.__bg-content {
		position: relative;
		z-index: 50;
		display: flex;
		padding: 32px;
		min-height: 284px;
		align-items: flex-end;
		border-radius: 0 0 30px 30px;
		justify-content: flex-start;
		.img-container,
		img {
			width: 160px;
			border-radius: 5px;
		}
		.img-container {
			height: 220px;
			background: $backgroundSecondary;
			// box-shadow: 0 18px 24px rgba(darken($backgroundPrimary, 8), .2);
		}
		img {
			max-height: 220px;
			object-fit: cover;
		}
		.edit-btn {
			position: absolute;
			top: 32px;
			right: 32px;
			display: flex;
			.logout-btn {
				margin: 0 12px 0 0;
				display: none;
			}
		}
	}
	.athlete--data {
		width: 100%;
		display: flex;
		align-items: flex-end;
	}
	.athlete--content {
		margin: 0 0 0 26px;
		min-width: 0;
		flex-grow: 1;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		h2 {
			color: darken($textWhite, 6);
			overflow: hidden;
			font-size: 26px;
			font-weight: 600;
			text-overflow: ellipsis;
		}
		.website {
			color: $primary;
			margin: 6px 0 0;
			font-weight: 600;
			&:hover {
				text-decoration: underline;
			}
		}
		.email {
			color: $textGrey;
			margin: 6px 0 0;
			overflow: hidden;
			font-size: 15px;
			font-weight: 600;
			text-overflow: ellipsis;
		}
	}
	.app--athlete-labels {
		margin: 0;
		display: inline-flex;
		flex-wrap: wrap;
		div {
			margin: 0 14px 0 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	span {
		margin: 0 12px 0 0;
	}
	.app--athlete-links {
		margin: 12px 0 0;
		display: flex;
		justify-content: center;
		.app-square-icon {
			width: 38px;
			height: 38px;
			margin: 0 14px 0 0;
			border: 0;
			min-width: auto;
			min-height: auto;
			background: rgba(45, 45, 45, .15);
			&:last-child {
				margin: 0;
			}
		}
	}
	@media screen and (max-width: 540px) {
		.__bg-content {
			padding: 22px;
			.edit-btn {
				width: 60%;
				@media screen and (max-width: 414px) {
					width: 80%;
				}
				margin: 18px 0 0;
				position: static;
				.logout-btn {
					display: block;
					flex-grow: 1;
				}
				.app-square-icon {
					border: 0;
				}
			}
		}
		.athlete--data {
			align-items: center;
			flex-direction: column;
		}
		.athlete--content {
			width: 100%;
			margin: 12px 0 0;
			align-items: center;
			h2 {
				padding: 0 22px;
				text-align: center;
			}
		}
	}
}
