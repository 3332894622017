.app--athlete-card {
	cursor: pointer;
	overflow: hidden;
	position: relative;
	box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .4);
	will-change: transform;
	transition: border-color .2s ease;
	&:after {
		z-index: 10;
		content: '';
		display: block;
		padding: 130% 0 0;
		border-radius: 3px;
	}
	&:hover .__photo {
		transform: scale(1.02);
	}
	&.selected {
		border-color: $primary;
	}
	&,
	.__photo,
	.__inner {
		border-radius: 5px;
	}
	.__photo,
	.__inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.__photo {
		z-index: 10;
		background: no-repeat center center/cover lighten($backgroundPrimary, 6);
		transition: transform .2s ease;
		will-change: transform;
		transform-origin: center bottom;
	}
	.__sport {
		position: absolute;
		top: 12px;
		left: 12px;
		z-index: 12;
		i {
			display: block;
			padding: 6px;
			font-size: 0;
			background: rgba($backgroundPrimary, .4);
			border-radius: 50%;
			backdrop-filter: blur(10px);
		}
		svg {
			fill: #fff;
			width: 14px;
			height: auto;
		}
	}
	.__price {
		position: absolute;
		z-index: 12;
		font-size: 0;
		top: 12px;
		left: 26px + 10px + 12px;
		right: 26px + 10px + 12px;
		text-align: center;
		span {
			max-width: 100%;
			color: darken($textWhite, 6);
			display: inline-block;
			background: rgba($backgroundPrimary, .4);
			padding: 3px 5px;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			border-radius: 5px;
			backdrop-filter: blur(10px);
		}
	}
	.__check {
		cursor: pointer;
		position: absolute;
		top: 12px;
		z-index: 12;
		right: 12px;
		width: 26px;
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(#fff, .9);
		background: $backgroundPrimary;
		box-shadow: 0 2px 4px rgba(darken($backgroundPrimary, 1), .2);
		transition: background-color .2s ease;
		border-radius: 5px;
		@include backface(26px);
		svg {
			opacity: 0;
			transition: opacity .2s ease;
			pointer-events: none;
		}
		&.checked {
			background: $primary;
			svg {
				opacity: 1;
			}
		}
	}
	.__inner {
		z-index: 11;
		padding: 20px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		$color: darken($backgroundSecondary, 6);
		@include gradient(
			to bottom,
			rgba($color, 0) 0%,
			rgba($color, 0.013) 8.1%,
			rgba($color, 0.049) 15.5%,
			rgba($color, 0.104) 22.5%,
			rgba($color, 0.175) 29%,
			rgba($color, 0.259) 35.3%,
			rgba($color, 0.352) 41.2%,
			rgba($color, 0.45) 47.1%,
			rgba($color, 0.55) 52.9%,
			rgba($color, 0.648) 58.8%,
			rgba($color, 0.741) 64.7%,
			rgba($color, 0.825) 71%,
			rgba($color, 0.896) 77.5%,
			rgba($color, 0.941) 84.5%,
			rgba($color, 0.967) 91.9%,
			$color 100%
		);
		h4 {
			font-size: 16.5px;
			font-weight: 700;
			text-align: center;
		}
		.athlete-school {
			color: $textGrey;
			text-align: center;
			margin: 4px 0 0;
			font-size: 12.5px;
			font-weight: 600;
			line-height: 1.4;
		}
	}
}
