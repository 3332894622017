.pages {
	display: flex;
	margin: 28px 0 0;
	li {
		cursor: pointer;
		border: 1px solid rgba(#fff, .15);
		margin: 0 12px 0 0;
		background: darken($backgroundSecondary, 4);
		transition: background-color .2s ease;
		border-radius: 3px;
		&:not(.disabled):hover {
			background: lighten($backgroundSecondary, 4);
		}
		a {
			display: block;
			padding: 10px;
			outline: none;
			font-weight: 600;
		}
		&:last-child {
			margin: 0;
		}
		&.disabled {
			cursor: default;
			opacity: .2;
		}
		&.selected {
			color: $primary;
			border-color: $primary;
			&:hover {
				background: rgba($primary, .15);
			}
		}
	}
}
