.app--r-select {
	display: flex;
	align-items: flex-start;
	.app-square-icon {
		margin-right: 15px;
		svg {
			width: 18px;
		}
	}
	[class$=control] {
		border: 1px solid $backgroundSecondary !important;
	}
	.app--r-select-container {
		width: 100%;
		display: flex;
		align-items: stretch;
		flex-direction: column;
	}
	p.note {
		margin: 10px 0 0;
		color: $textGrey;
		text-align: right;
		font-size: 13px;
		font-weight: 600;
	}
	&.invalid {
		[class$=control] {
			border-color: $danger !important;
		}
	}
}
