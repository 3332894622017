.app--date-picker {
	display: flex;
	align-items: center;
	justify-content: stretch;
	.app-square-icon {
		margin: 0 15px 0 0;
	}
	&.invalid {
		.form-control {
			border-color: $danger;
		}
	}
}

.app--date-picker-container {
	width: 100%;
	.form-control {
		width: 100%;
		font: 600 14px $font, Sans-serif;
		color: $textWhite;
		padding: 15px 20px;
		padding-right: 36px;
		background: $backgroundTertiary;
		border-radius: 5px;
		@include placeholder(rgba(#fff, .6), rgba(#fff, .1));
	}
}

.app--date-picker-element {
	position: relative;
	svg {
		position: absolute;
		top: calc(50% - 2px);
		right: 20px;
	}
}

.rdt {
	position: relative;
}

.rdtPicker {
	display: none;
	position: absolute;
	min-width: 250px;
	padding: 4px;
	margin-top: 1px;
	z-index: 99999 !important;
	background: lighten($backgroundTertiary, 2);
	box-shadow: 0 4px 24px darken($backgroundTertiary, 10);
	border-radius: 10px;
}

.rdtOpen .rdtPicker {
	display: block;
}

.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker {
	.rdtTimeToggle {
		text-align: center;
		border-radius: 10px;
	}
	table {
		width: 100%;
		margin: 0;
	}
	td,
	th {
		text-align: center;
		height: 28px;
	}
	td {
		border-radius: 10px;
		cursor: pointer;
		&.rdtDay:hover,
		&.rdtHour:hover,
		&.rdtMinute:hover,
		&.rdtSecond:hover {
			background: $backgroundSecondary;
			cursor: pointer;
		}
	}
	.rdtTimeToggle:hover {
		background: $backgroundSecondary;
		cursor: pointer;
	}
	td {
		&.rdtOld,
		&.rdtNew {
			color: #999999;
		}
		&.rdtToday {
			position: relative;
			&:before {
				content: "";
				display: inline-block;
				border-left: 7px solid transparent;
				border-bottom: 7px solid $primary;
				border-top-color: rgba(0, 0, 0, 0.2);
				position: absolute;
				bottom: 4px;
				right: 4px;
			}
		}
		&.rdtActive {
			background-color: $primary;
			color: #000;
			&:hover {
				background-color: $primary;
				color: #000;
			}
			&.rdtToday:before {
				border-bottom-color: #000;
			}
		}
		&.rdtDisabled {
			background: none;
			color: #999999;
			cursor: not-allowed;
			&:hover {
				background: none;
				color: #999999;
				cursor: not-allowed;
			}
		}
		span {
			&.rdtOld {
				color: #999999;
			}
			&.rdtDisabled {
				background: none;
				color: #999999;
				cursor: not-allowed;
				&:hover {
					background: none;
					color: #999999;
					cursor: not-allowed;
				}
			}
		}
	}
	th {
		// border-bottom: 1px solid #f9f9f9;
	}
	.dow {
		width: 14.2857%;
		border-bottom: none;
		cursor: default;
	}
	th {
		&.rdtSwitch {
			width: 100px;
			border-radius: 10px;
		}
		&.rdtNext,
		&.rdtPrev {
			font-size: 21px;
			vertical-align: top;
			border-radius: 10px;
		}
	}
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none;
	user-select: none;
}

.rdtPicker {
	th.rdtDisabled {
		background: none;
		color: #999999;
		cursor: not-allowed;
		&:hover {
			background: none;
			color: #999999;
			cursor: not-allowed;
		}
	}
	thead tr:first-of-type th {
		cursor: pointer;
		&:hover {
			background: $backgroundSecondary;
		}
	}
	tfoot {
		border-top: 1px solid #f9f9f9;
	}
	button {
		border: none;
		background: none;
		cursor: pointer;
		&:hover {
			background-color: #eee;
		}
	}
	thead button {
		width: 100%;
		height: 100%;
	}
}

td {
	&.rdtMonth,
	&.rdtYear {
		height: 50px;
		width: 25%;
		cursor: pointer;
	}
	&.rdtMonth:hover,
	&.rdtYear:hover {
		background: #eee;
	}
}

.rdtCounters {
	display: inline-block;
	& > div {
		float: left;
	}
}

.rdtCounter {
	height: 100px;
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter {
	.rdtBtn {
		height: 40%;
		line-height: 40px;
		cursor: pointer;
		display: block;
		-webkit-touch-callout: none;
		user-select: none;
		&:hover {
			background: #eee;
		}
	}
	.rdtCount {
		height: 20%;
		font-size: 1.2em;
	}
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
	input {
		width: 100%;
		font-size: 1.2em;
		margin-top: 37px;
	}
}

.rdtTime td {
	cursor: default;
}
