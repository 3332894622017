@keyframes fadeIn {
	from { opacity: .4; }
}

// $c: darken($backgroundSecondary, 1);

.app--skeleton {
	position: absolute;
	top: 22px;
	left: 22px;
	right: 22px;
	bottom: 0;
	overflow: hidden;
	.skeleton--item {
		opacity: .8;
		position: relative;
		background: lighten($backgroundPrimary, 4);
		border-radius: 5px;
		// @include gradient(
		// 	to bottom,
		// 	rgba($c, 0) 0%,
		// 	rgba($c, 0.013) 8.1%,
		// 	rgba($c, 0.049) 15.5%,
		// 	rgba($c, 0.104) 22.5%,
		// 	rgba($c, 0.175) 29%,
		// 	rgba($c, 0.259) 35.3%,
		// 	rgba($c, 0.352) 41.2%,
		// 	rgba($c, 0.45) 47.1%,
		// 	rgba($c, 0.55) 52.9%,
		// 	rgba($c, 0.648) 58.8%,
		// 	rgba($c, 0.741) 64.7%,
		// 	rgba($c, 0.825) 71%,
		// 	rgba($c, 0.896) 77.5%,
		// 	rgba($c, 0.941) 84.5%,
		// 	rgba($c, 0.967) 91.9%,
		// 	$c 100%
		// );
		&:after {
			z-index: 10;
			content: '';
			display: block;
			padding: 130% 0 0;
			border-radius: 3px;
		}
		.inner {
			position: absolute;
			padding: 22px 20px;
			bottom: 0;
			right: 0;
			left: 0;
		}
		.long--item {
			width: 70%;
			margin: auto;
			height: 8px;
			animation: fadeIn 1s infinite alternate ease;
			background: lighten($backgroundPrimary, 12);
			border-radius: 3px;
		}
		.short--items {
			margin: 18px auto 0;
			display: flex;
			justify-content: center;
			i {
				width: 16%;
				height: 8px;
				margin: 0 6px;
				display: block;
				animation: fadeIn 1s infinite alternate ease .2s;
				background: lighten($backgroundPrimary, 8);
				border-radius: 3px;
			}
		}
	}
	&.type--notifications .skeleton--item {
		margin: 0 0 20px;
		.inner {
			top: 0;
			padding: 16.5px 18.5px;
			position: static;
		}
		.long--item {
			width: 63%;
			margin: 0;
		}
		.short--items {
			margin: 16px 0 0;
			justify-content: flex-start;
			i {
				margin: 0;
			}
		}
		&:last-child {
			margin: 0;
		}
		&:after {
			display: none;
		}
	}
}
